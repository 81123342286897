<template>
  <div>
    <load-profile></load-profile>

    <n-search-container
      :show-header="true"
      @search="search"
      @reset="reset"
      :fields="searchFields"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t('general.entries') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', 'honor-of-king')">
            <b-button
              variant="primary"
              :to="{ name: 'create-honor-of-king-package' }"
              >{{ $t('button.create') }}</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(image)="data">
            <b-img-lazy
              :src="data.item.mediaFile"
              :width="100"
              :height="100"
              :blank-src="defaultImage"
              v-if="data.item.mediaFile"
            />
            <b-img-lazy
              :src="defaultImage"
              :width="100"
              :height="100"
              :blank-src="defaultImage"
              v-else
            />
          </template>
          <template #cell(isEnable)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                :to="{
                  name: 'view-honor-of-king-package',
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('general.view')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-if="$can('update', 'honor-of-king')"
                :to="{
                  name: 'update-honor-of-king-package',
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <n-button-delete
                variant="danger"
                pill
                v-b-tooltip.hover
                :title="$t('general.delete')"
                size="sm"
                @confirm="deleteItem(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </n-button-delete>
            </div>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BImgLazy,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NButtonDelete from '@/components/NButtonDelete'
import NTable from '@/components/NTable'
import tableFields from './tableField'
import searchInputs from './searchInput'
import { avatarText } from '@core/utils/filter'

const PackageRepository = Repository.get('package')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BImgLazy,
  },
  watch: {
    perPage(value) {
      this.list(1)
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
    activeSearch() {
      return !!this.$route.query.name || !!this.$route.query.isEnable
    },
    fields() {
      let fieldKeys = tableFields.map((item) => {
        if (item.key == 'name') {
          item.sortField = `name_${this.$i18n.locale}`
        }
        return item
      })
      return fieldKeys
    },
  },

  data() {
    this.searchFields = this.searchFields.map((field) => {
      if (field.key == 'popular') {
        field.options = [
          { id: 'desc', name: this.$t('field.most') },
          { id: 'asc', name: this.$t('field.least') },
        ]
      }
      if (field.key == 'price') {
        field.options = [
          { id: 'desc', name: this.$t('field.most') },
          { id: 'asc', name: this.$t('field.least') },
        ]
      }

      return field
    })
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        displayText: this.$route.query.name || null,
        isEnable: this.$route.query.isEnable || null,
        limit: this.$store.state.pagination.perPage || 10,
        displayText: this.$route.query.name || null,
        sortPopular: this.$route.query.sortPopular || null,
        sortPrice: this.$route.query.sortPrice || null,
        price: this.$route.query.price || null,
        popular: this.$route.query.popular || null,
        gameTypeId: 17,
      },
      avatarText,
      defaultImage: require('@/assets/images/placeholder.png'),
    }
  },
  mounted() {
    this.getData(this.params.page)
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params })
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData()
    },
    search(searchText) {
      this.params.search = searchText
      this.updateQuerySting()
      this.getData()
    },
    reset() {
      this.params.search = ''
      this.params.sortPopular = null
      this.params.sortPrice = null
      this.$refs.search.reset()
      this.updateQuerySting()
      this.getData()
    },
    spliceItem(id) {
      const deleteIndex = this.items.findIndex((item) => {
        return item.id === id
      })

      this.items.splice(deleteIndex, 1)
      this.total -= 1
    },
    deleteItem(id) {
      PackageRepository.delete(id)
        .then((response) => {
          this.spliceItem(id)
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false
        })
    },
    getData() {
      if (this.params.popular) {
        this.params.sortPopular = this.params.popular.id
      }
      if (this.params.price) {
        this.params.sortPrice = this.params.price.id
      }
      this.loading = true
      PackageRepository.honorKing({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          let data = response.data.data.list
          this.items = [...data]
          this.total = response.data.data.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
  setup() {
    let keyFields = [...tableFields]
    const searchFields = [...searchInputs]
    return {
      keyFields,
      searchFields,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
